import { Divider, Row, Col } from "antd";
import React from "react";
import UpdateServersModal from "./UpdateServersModal"

class AdminTools extends React.Component {

  render() {
    return (
      <>
        {(function() {
          if (current_user.role == 'admin') {
            return (
              <>
                <Divider orientation="left">Admin Tools</Divider>
                <Row>
                  <Col span={12}><UpdateServersModal /></Col>
                </Row>
              </>
            );
          } else {
            return (
              <>
              </>
            );
          }
        })()}
      </>
    )
  }
}
export default AdminTools;

