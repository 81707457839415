import React from "react";
import { Layout, Typography, Affix } from "antd";
import Menu from "./Menu";

const { Header } = Layout;
const { Title } = Typography;
const { Text } = Typography;

export default () => (
  <Affix>
    <Header style={{ height: "180px" }}>
      <div className="logo" />
      <Title style={{ color: "white" }}>STRIKE</Title>
      <Title level={2} style={{ color: "white" }}>S<small>ystem for</small> T<small>esting,</small> R<small>eleasing, content</small> I<small>ntegration and</small> K<small>eeping track of</small> E<small>verything</small></Title>
      <Text style={{ color: "white" }}>{current_user.email}<small>({current_user.role})</small></Text> (<a href="/sign_in">Log out</a>)
      <Menu />
    </Header>
  </Affix>
);