import { Button, Form, Input, Modal, Select, Table, Divider } from "antd";
import axios from 'axios';
import React from "react";

const { Option } = Select;
const { TextArea } = Input;

class UpdateProviderScreenshotsModal extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      visible: false,
      locales: [],
      verticals: [],
      button_loading_get: false,
      button_loading_apply: false,
      headline: 'Update Screenshots',
      api_url_update: 'api/v1/update_provider_screenshots',
      api_url_get_changes: 'api/v1/get_changed_screenshots',
      results: [],
      results_headline: '',
      show_results: false
    };
  }

  componentDidMount() {
    axios.get("api/v1/verticals/")
      .then(res => {
        const verticals = res.data['verticals'];
        this.setState({ verticals });
      });

  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onFinishGet = (values) => {
    this.setState({
      button_loading_get: true,
      results_headline: 'working...'
    });
    const url = this.state.api_url_get_changes;
    axios.get(url, { params: values })
      .then(res => {
        let results_headline = '';
        let show_results = false;
        if (res.data['screenshots'].length == 0) {
          results_headline = 'No changes found';
        } else {
          results_headline = 'Changed screenshots:';
          show_results = true;
        }
        this.setState({
          results: res.data['screenshots'],
          button_loading_get: false,
          results_headline: results_headline,
          show_results: show_results
        });
      });
  };

  onClickApply = () => {
    this.setState({
      button_loading_apply: true
    });
    const url = this.state.api_url_update;
    axios.post(url, { to_update: this.state.results })
      .then(res => {
        this.setState({
          show_results: false,
          results_headline: 'Finished!',
          button_loading_apply: false
        });
      });
  }

  render() {
    const columns = [
      {
        title: 'Provider Code',
        dataIndex: 'provider_code',
        key: 'provider_code'
      },
      {
        title: 'Current Screenshot',
        dataIndex: 'cf_data',
        key: 'cf_data',
        render: (text, record) => {
          return (
            <img src={`data:image/jpeg;base64,${record.cf_data}`} />
          )
        }
      },
      {
        title: 'New Screenshot',
        dataIndex: 'directus_data',
        key: 'directus_data',
        render: (text, record) => {
          return (
            <img src={`data:image/jpeg;base64,${record.directus_data}`} />
          )
        }
      }
    ];
    return (
      <>
        <Button onClick={this.showModal}>
          {this.state.headline}
        </Button>
        <Modal
          title={this.state.headline}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          width={800}
        >
          <Form ref={this.formRef} layout="vertical" onFinish={this.onFinishGet}>
            <Form.Item
              name="vertical"
              label="Vertical"
              rules={[
                {
                  required: true,
                  message: "Please input vertical!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select vertical"
                optionFilterProp="children"
                style={{ width: "100%" }}
              >
                {this.state.verticals.map((vertical) => (
                  <Option key={vertical.value} value={vertical.value}>{vertical.name}</Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={this.state.button_loading_get}>Look for changed screenshots</Button>
            </Form.Item>
          </Form>
          <h2>{this.state.results_headline}</h2>
          <div style={{ display: this.state.show_results ? "block" : "none" }}>
            <Table dataSource={this.state.results} columns={columns} size="small" pagination={false} />
            <Divider />
            <Button type="primary" loading={this.state.button_loading_apply} onClick={this.onClickApply} danger block>Apply Changes!</Button>
          </div>
        </Modal>
      </>
    )
  }

}
export default UpdateProviderScreenshotsModal;