import { Button, Form, Input, Modal, Select } from "antd";
import axios from 'axios';
import React from "react";

const { Option } = Select;
const { TextArea } = Input;

class UpdateModal extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      visible: false,
      update_id: '',
      changed_files: [],
      locales: [],
      verticals: [],
      button_loading: false
    };
    switch (props.target) {
      case 'reviews':
        this.state.headline = "Update Reviews"
        this.state.api_url = "api/v1/reviews/"
        this.state.target = 'reviews'
      break
      case 'lists':
        this.state.headline = "Update List Config"
        this.state.api_url = "api/v1/list/"
        this.state.target = 'list'
      break
      case 'articles':
        this.state.headline = "Update Articles"
        this.state.api_url = "api/v1/articles/"
        this.state.target = 'articles'
      break
      case 'content':
        this.state.headline = "Update Content"
        this.state.api_url = "api/v1/content/"
        this.state.target = 'content'
      break
      case 'toplists':
        this.state.headline = "Update TopLists"
        this.state.api_url = "api/v1/toplists/"
        this.state.target = 'toplists'
      break
    }
  }

  onFinish = (values) => {
    this.setState({
      button_loading: true
    });
    const url = this.state.api_url;
    values['user'] = current_user.email;
    values['type'] = this.state.target;
    fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then(response => response.json())
      .then((data) => {
        this.setState({
          update_id: data.update_id,
          changed_files: data.changed_files,
          button_loading: false
        });
        console.log(data);
        return data;
      })
      .catch((err) => console.error("Error: " + err));
  };

  componentDidMount() {
    axios.get("api/v1/locales/")
      .then(res => {
        const locales = res.data['locales'];
        this.setState({ locales });
      });
    axios.get("api/v1/verticals/")
      .then(res => {
        const verticals = res.data['verticals'];
        this.setState({ verticals });
      });

  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };
  render() {
    return (
      <>
        <Button onClick={this.showModal}>
          { this.state.headline }
        </Button>

        <Modal
          title={ this.state.headline }
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Form ref={this.formRef} layout="vertical" onFinish={this.onFinish}>
            <Form.Item
              name="vertical"
              label="Vertical"
              rules={[
                {
                  required: true,
                  message: "Please input vertical!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select vertical"
                optionFilterProp="children"
                style={{ width: "100%" }}
              >
                {this.state.verticals.map((vertical) => (
                  <Option key={vertical.value} value={vertical.value}>{vertical.name}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="locale"
              label="Locale"
              rules={[
                {
                  required: true,
                  message: "Please input locale!",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select locale"
                optionFilterProp="children"
                style={{ width: "100%" }}
              >
                {this.state.locales.map((locale) => (
                  <Option key={locale.value} value={locale.value}>{locale.name}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="comment"
              label="Comment"
              rules={[
                {
                  required: false,
                },
              ]}
            >
              <TextArea rows={5} />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={this.state.button_loading}>Submit</Button>
            </Form.Item>
          </Form>
          <hr />
          <pre>
            {this.state.update_id}
            <br />
            ---
            <br />
            {this.state.changed_files.map((filename, index) => (
              <p key={index}>{filename}</p>
            ))}
          </pre>

        </Modal>
      </>
    )
  }
}

export default UpdateModal;