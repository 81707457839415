import { Button, Form, Input, Modal, Select } from "antd";
import axios from 'axios';
import React from "react";

const { Option } = Select;

class UpdateServersModal extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      visible: false,
      command_id: '[no command running]',
      results: [],
      button_loading: false,
      headline: 'Update DR Server Website Backend',
      api_url: 'api/v1/update_servers',
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onFinish = (values) => {
    this.setState({
      button_loading: true
    });
    const url = this.state.api_url;
    axios.get(url, { params: values })
      .then(res => {
        this.setState({
          command_id: res.data['command_id'],
          results: res.data['results'],
          button_loading: false
        });
      });
  };

  render() {
    return (
      <>
        <Button danger onClick={this.showModal}>
          {this.state.headline}
        </Button>
        <Modal
          title={this.state.headline}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Form ref={this.formRef} layout="vertical" onFinish={this.onFinish}>
            <Form.Item
              name="type"
              label="Instance Type"
              rules={[
                {
                  required: true,
                  message: "Instance type to run command on?",
                },
              ]}
            >
              <Select
                placeholder="Select Instance Type"
                style={{ width: "100%" }}
              >
                <Option value='test_server'>Test</Option>
                <Option value='live_server'>Production</Option>
              </Select>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={this.state.button_loading}>Submit</Button>
            </Form.Item>
          </Form>
          <p>Command results for command {this.state.command_id}</p>
          <ul>
            {this.state.results.map((result) => (
              <li>{result['instance_id']}: {result['status']}</li>
            ))}
          </ul>
        </Modal>
      </>
    )
  }
}
export default UpdateServersModal;