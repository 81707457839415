import { Button, Form, Input, Modal, Select } from "antd";
import axios from 'axios';
import React from "react";

const { Option } = Select;
const { TextArea } = Input;

class ProviderCopyModal extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      visible: false,
      locales: [],
      verticals: [],
      button_loading: false,
      headline: 'Copy Provider Entries',
      api_url: 'api/v1/copy_providers/',
    };
  }

  componentDidMount() {
    axios.get("api/v1/locales/")
      .then(res => {
        const locales = res.data['locales'];
        this.setState({ locales });
      });
    axios.get("api/v1/verticals/")
      .then(res => {
        const verticals = res.data['verticals'];
        this.setState({ verticals });
      });

  };

  showModal = () => {
    this.setState({
      visible: true,
    });
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

  onFinish = (values) => {
    this.setState({
      button_loading: true
    });
    const url = this.state.api_url;
    fetch(url, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(values),
    })
      .then(response => response.json())
      .then((data) => {
        this.setState({
          button_loading: false
        });
        console.log(data);
        return data;
      })
      .catch((err) => console.error("Error: " + err));
  };


  render() {
    return (
      <>
        <Button onClick={this.showModal}>
          {this.state.headline}
        </Button>

        <Modal
          title={this.state.headline}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Form ref={this.formRef} layout="vertical" onFinish={this.onFinish}>
            <Form.Item
              name="vertical"
              label="From vertical"
              rules={[
                {
                  required: true,
                  message: "From which vertical do you want to copy?",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select vertical"
                optionFilterProp="children"
                style={{ width: "100%" }}
              >
                {this.state.verticals.map((vertical) => (
                  <Option key={vertical.value} value={vertical.value}>{vertical.name}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="locale_from"
              label="From locale"
              rules={[
                {
                  required: true,
                  message: "From which locale do you want to copy?",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select locale"
                optionFilterProp="children"
                style={{ width: "100%" }}
              >
                {this.state.locales.map((locale) => (
                  <Option key={locale.value} value={locale.value}>{locale.name}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="locale_to"
              label="To locale"
              rules={[
                {
                  required: true,
                  message: "To which locale do you want to copy?",
                },
              ]}
            >
              <Select
                showSearch
                placeholder="Select locale"
                optionFilterProp="children"
                style={{ width: "100%" }}
              >
                {this.state.locales.map((locale) => (
                  <Option key={locale.value} value={locale.value}>{locale.name}</Option>
                ))}
              </Select>
            </Form.Item>

            <Form.Item
              name="provider_original"
              label="Providers to copy"
              rules={[
                {
                  required: true,
                },
              ]}
            >
              <TextArea rows={8} placeholder="List of provider codes.&#10;One per line!&#10;Complete codes like 'match-en-gb'!" />
            </Form.Item>

            <Form.Item>
              <Button type="primary" htmlType="submit" loading={this.state.button_loading}>Submit</Button>
            </Form.Item>


          </Form>

        </Modal>
      </>
    )
  }
}

export default ProviderCopyModal;