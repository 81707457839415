import React from "react";
import { Menu } from "antd";
import { Link } from 'react-router-dom';

export default () => (
  <Menu
  mode="horizontal"
  theme="dark"
  >
    <Menu.Item key="content">
      <Link to="/">Content Management</Link>
    </Menu.Item>
    <Menu.Item key="sitecheck">
      <Link to="/sc">Site Check</Link>
    </Menu.Item>
  </Menu>
);