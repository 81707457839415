import { Layout, Form, Select, Button, Table } from "antd";
import React from "react";
import axios from 'axios';
import Header from "./Header";
import Footer from "./Footer";

const { Content } = Layout;

class SiteCheck extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      projects: [],
      api_url: 'api/v1/get_site_check/',
      reports: []
    };
  };
  componentDidMount() {
    axios.get("api/v1/projects/")
      .then(res => {
        const projects = res.data['projects'];
        this.setState({ projects });
      });
  };
  onFinish = (values) => {
    const url = this.state.api_url;
    axios.get(url, { params: values })
      .then(res => {
        if (res.data !== false) {
          this.setState({
            reports: data.reports
          })
        }
      });
  };

  render() {
    const columns = [
       {
        title: 'Project ID',
        dataIndex: 'project_id',
        key: 'project_id'
      },
      {
        title: 'Page',
        dataIndex: 'page',
        key: 'page'
      },
      {
        title: 'Device',
        dataIndex: 'device',
        key: 'device'
      },
      {
        title: 'Element',
        dataIndex: 'type',
        key: 'type'
      },
      {
        title: 'URL',
        dataIndex: 'url',
        key: 'url'
      },
      {
        title: 'status',
        dataIndex: 'status',
        key: 'status'
      }
      ];
    return (
      <>
        <Layout className="layout">
          <Header />
          <Content style={{ padding: "0 50px" }}>
            <div className="site-layout-content" style={{ margin: "100px auto" }}>
              <h1>DR Site Check</h1>
              <Form ref={this.formRef} layout="inline" onFinish={this.onFinish}>
                <Form.Item
                  name="project"
                  label="Project"
                  rules={[
                    {
                      required: true,
                      message: "Please select project!",
                    },
                  ]}
                >
                  <Select
                    showSearch
                    placeholder="Select project"
                    optionFilterProp="children"
                    style={{ width: "200" }}
                  >
                    {this.state.projects.map((project) => (
                      <Option key={project.value} value={project.value}>{project.name}</Option>
                    ))}
                  </Select>
                </Form.Item>
                <Form.Item>
                  <Button type="primary" htmlType="submit">Start</Button>
                </Form.Item>
              </Form>
            </div>
          </Content>
          <Footer />
        </Layout>
      </>
    )
  }
}
export default SiteCheck;

