import { Button, Form, Input, Modal, Select } from "antd";
import axios from 'axios';
import React from "react";

class AceModal extends React.Component {
  constructor(props) {
    super(props);
    this.formRef = React.createRef();
    this.state = {
      visible: false,
      running_jobs: [],
      button_loading: false,
      headline: 'ACE Ad Copy Engine',
      api_url: 'api/v1/ace/acgen',
    };
  }

  showModal = () => {
    this.setState({
      visible: true,
    });
    this.updateData();
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
    this.updateData();
  };

  onFinish = (values) => {
    const url = this.state.api_url;
    axios.get(url, { params: values });
    this.updateData()
  };

  componentDidMount() {
    setInterval(() => this.updateData(), 1000)
  }

  updateData() {
    if (this.state.visible) {
      axios.get("api/v1/ace/jobs")
        .then(res => {
          const running_jobs = res.data['jobs'];
          this.setState({ running_jobs: running_jobs });
        })
    }
  }

  render() {
    return (
      <>
        <Button onClick={this.showModal}>
          {this.state.headline}
        </Button>
        <Modal
          title={this.state.headline}
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
        >
          <Form ref={this.formRef} layout="vertical" onFinish={this.onFinish}>
            <Form.Item
              name="confid"
              label="Config ID"
              rules={[
                {
                  required: true,
                  message: "Config ID (directus)",
                },
              ]}
            >
              <Input></Input>
            </Form.Item>
            <Form.Item>
              <Button type="primary" htmlType="submit" loading={this.state.button_loading}>Submit</Button>
            </Form.Item>
          </Form>
          <p>Currently running jobs:</p>
          <ul>
            {this.state.running_jobs.map((job) => (
              <li>{job}</li>
            ))}
          </ul>
        </Modal>
      </>
    )
  }
}
export default AceModal;