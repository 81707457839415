import { Modal, Button, Table, Tag, Space, Link, Badge, Spin } from "antd";
import React from "react";
import axios from 'axios';

class PublishTestModal extends React.Component {
  formRef = React.createRef();
  state = {
    visible: false,
    updates: [],
    loading: false
  };

  showModal = () => {
    this.setState({
      visible: true,
    });
    this.updateData();
  };

  handleCancel = () => {
    this.setState({
      visible: false,
    });
  };

//  componentDidMount() {
//    this.updateData();
//  };

  handleClick = (data) => {
    this.setState({ loading: true });
    axios.post('api/v1/publish/', data)
      .then(res => {
        console.log(res.data);
        this.setState({ loading: false });
      })
    this.updateData();
  }

  handleClickToUpdate = (data) => {
    this.setState({ loading: true });
    axios.patch('api/v1/website_updates/', { "key": data.key, "to_update": true });
    this.updateData();
  }

  handlePullRequestCreateClick = (data) => {
    this.setState({ loading: true });
    axios.post('api/v1/create_pull_request/', { "key": data.key, "update_id": data.update_id, "comment": data.comment })
      .then(res => {
        console.log(res.data);
        this.setState({ loading: false });
      })
    console.log(data);
    console.log(data.update_id);
    this.updateData();
  }

  handlePullRequestMergeClick = (data) => {
    this.setState({ loading: true });
    axios.post('api/v1/merge_pull_request/', { "key": data.key, "pull_request_id": data.pull_request_id })
      .then(res => {
        console.log(res.data);
        this.setState({ loading: false });
      })
    console.log(data.pull_request_id);
    this.updateData();
  }

  updateData() {
    this.setState({ loading: true });
    axios.get("api/v1/sync_website_update_to_cc/");
    axios.get("api/v1/website_updates/")
      .then(res => {
        this.setState({ updates: res.data['update_list'], loading: false });
      })
  }

  render() {
    const values = this.state.updates;
    const columns = [
      {
        title: 'Date',
        dataIndex: 'date_created',
        key: 'date_created',
        sorter: (a, b) => new Date(a.date_created) - new Date(b.date_created)
      },
      {
        title: 'ID',
        dataIndex: 'update_id',
        key: 'update_id',
      },
      {
        title: 'Locale',
        width: 50,
        dataIndex: 'locale',
        key: 'locale',
      },
      {
        title: 'Vertical',
        width: 60,
        dataIndex: 'vertical',
        key: 'vertical',
      },
      {
        title: 'Type',
        width: 60,
        dataIndex: 'type',
        key: 'type',
      },
      {
        title: 'User',
        dataIndex: 'user_submitted',
        key: 'user_submitted'
      },
      {
        title: 'Comment',
        dataIndex: 'comment',
        key: 'comment',
        ellipsis: true,
      },
      {
        title: 'Action',
        key: 'action',
        render: (text) => {
          let test_url = "http://" + text.vertical + "-" + text.locale + ".test.digitalreviews.com/top?configversion=" + text.update_id;
          if (Array.isArray(text.published_to) && text.published_to.includes('test01') && text.to_update) {
            return (
              <Space size="middle">
                <a href={test_url} target="_blank">open on test01</a>
              </Space>
            )
          } else if (Array.isArray(text.published_to) && text.published_to.includes('test01')) {
            return (
              <Space size="middle">
                <a href={test_url} target="_blank">open on test01</a>
                <a href="javascript:;" onClick={() => this.handleClickToUpdate(text)}>request update</a>
              </Space>
            )
          } else {
            return (
              <Space size="middle">
                <a href="javascript:;" onClick={() => this.handleClick(text)}>Publish to test01</a>
              </Space>
            )
          }
        }
      },
      {
        title: 'to update?',
        width: 70,
        key: 'to_update',
        render: (text) => {
          if (text.to_update) {
            return (
              <Badge status="success" />
            )
          } else {
            return (
              <Badge status="error" />
            )
          }
        }
      },
      {
        title: 'is merged?',
        width: 80,
        key: 'is_merged',
        render: (text) => {
          if (text.is_merged) {
            return (
              <Badge status="success" />
            )
          } else {
            return (
              <Badge status="error" />
            )
          }
        }
      },
      {
        title: 'Pull Request',
        key: 'pull_request',
        render: (text) => {
          if (text.pull_request_status == 'OPEN') {
            return (
              <Space size="middle">
                <a href="javascript:;" onClick={() => this.handlePullRequestMergeClick(text)}>MERGE</a>
              </Space>
            )
          } else if (text.pull_request_status == 'CLOSED') {
            return (
              <Space size="middle">
                closed
              </Space>
            )
          }
          else {
            return (
              <Space size="middle">
                <a href="javascript:;" onClick={() => this.handlePullRequestCreateClick(text)}>create</a>
              </Space>
            )
          }
        }
      }
    ];

    return (
      <>
        <Button onClick={this.showModal}>
          Publish
        </Button>
        <Modal
          title="Publish To Test"
          visible={this.state.visible}
          onCancel={this.handleCancel}
          footer={null}
          width={1200}
          size="small"
        >
          <Table dataSource={[...values]} columns={columns} size="small" pagination={false} loading={this.state.loading} />
        </Modal>
      </>
    )
  }
}

export default PublishTestModal;