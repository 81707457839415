import React from "react";
import { Row, Col, Divider } from 'antd';
import UpdateModal from "./UpdateModal"
import UpdateProviderLogosModal from "./UpdateProviderLogosModal"
import UpdateProviderScreenshotsModal from "./UpdateProviderScreenshotsModal"
import PublishTestModal from "./PublishTestModal"
import ProviderCopyModal from "./ProviderCopyModal"
import AdminTools from "./admin/AdminTools"
import AceModal from "./AceModal"
import UpdateWebsiteDataModal from "./UpdateWebsiteDataModal"
import RunAdreflectorModal from "./RunAdreflectorModal"

export default () => (
  <div className="container">
    <h2>Options</h2>
    <p className="lead">
      Update almost everything!
    </p>
    <Divider orientation="left">Export updates</Divider>
    <Row gutter={[8, 24]}>
      <Col span={6}><UpdateModal target="reviews" /></Col>
      <Col span={6}><UpdateModal target="lists" /></Col>
      <Col span={6}><UpdateModal target="content" /></Col>
      <Col span={6}><UpdateModal target="articles" /></Col>
      <Col span={6}><UpdateModal target="toplists" /></Col>

      <Col span={6}><UpdateProviderLogosModal /></Col>
      <Col span={6}><UpdateProviderScreenshotsModal /></Col>
    </Row>
    <Divider orientation="left">Publish</Divider>
    <Row gutter={[8, 24]}>
      <Col span={6}><PublishTestModal /></Col>
      <Col span={6}><UpdateWebsiteDataModal /></Col>
    </Row>
    <Divider orientation="left">Tools</Divider>
    <Row gutter={[8, 24]}>
      <Col span={6}><ProviderCopyModal /></Col>
      <Col span={6}><AceModal /></Col>
      <Col span={6}><RunAdreflectorModal /></Col>
    </Row>
    <AdminTools />
  </div>
);